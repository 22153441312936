<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">자산</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>구분</th>
            <td>{{ newUsedName }}</td>
            <th>자산명</th>
            <td>{{ assetVoucher.goodsName }}</td>
          </tr>
          <tr>
            <th>자산분류</th>
            <td>{{ assetVoucher.assetMainClassificationName }}</td>
            <th>수량</th>
            <td>{{ currency(assetVoucher.amount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatementAsset",
  props: {
    assetVoucher: Object,
  },
  computed: {
    newUsedName() {
      if (this.assetVoucher.newUsed === "NEW") return "신규";
      else if (this.assetVoucher.newUsed === "A") return "자본적 지출";
      return "";
    },
  },
  methods: {},
};
</script>
